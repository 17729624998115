<template>
	<div class="w100 bg-F7F7F7 paddt20">
		<!-- 素材市场 -->
		<div class="relative" v-if="type==1">
			<img class="bg-pic w100 display-block" src="@/assets/img/login_bg.png" alt="" />
			<div class="absolute top0 left0 right0 bottom0">
				<div class="w1200 top-main paddt85 display-flex">
					<!-- <img class="img_02 display-block" :src="pagedata.image" alt="" /> -->
					<el-image class="img_02 display-block"  :src="pagedata.image" lazy></el-image>
					<div class="flex-1 marl22">
						<div class="fz28 co-FFFFFF font-blod">{{pagedata.title}}</div>
						<div class="fz24 co-FFFFFF mart14"></div>
						<div class="align-items-center mart38">
							<img class="display-block de_01" src="@/assets/img/de_01.png" alt="" />
							<span class="fz24 co-FFFFFF marl12">{{pagedata.create_time}}发布</span>
							<img class="display-block marl46 de_01" src="@/assets/img/de_see.png" alt="" />
							<span class="fz24 co-FFFFFF marl12">{{pagedata.view}}</span>
							<img class="display-block marl46 de_01" src="@/assets/img/de_03.png" alt="" />
							<span class="fz24 co-FFFFFF marl12">{{pagedata.favorite}}</span>
							<img class="display-block marl46 de_01" src="@/assets/img/down.png" alt="" />
							<span class="fz24 co-FFFFFF marl12">{{pagedata.download}}</span>
						</div>
						<div class="mart31 justify-content-center-between"  >
							<div class="align-items-center cure-pointer" @click="rightClick" >
								<img class="display-block de_01 cure-pointer" src="@/assets/img/de_04.png" alt="" />
								<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_02.png"
									alt="" />
								<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_05.png"
									alt="" />
								<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_06.png"
									alt="" />
							</div>

							<div class="align-items-center">
								<div class="btn-01 cure-pointer center" @click="like(2)">
									<img class="display-block de_01"
										:src="require(`@/assets/img/${pagedata.is_collect==1 ? 'de_collect' : 'de_no_collect'}.png`)"
										alt="" />
									<span class="fz22 display-block marl14"
										:class="pagedata.is_collect==1 ? 'co-010085' : 'co-333333'">{{pagedata.is_collect ? '已收藏' : '收藏'}}</span>
								</div>

								<div class="btn-01 cure-pointer center marl30" @click="like(1)">
									<img class="display-block de_01"
										:src="require(`@/assets/img/${pagedata.is_favorite ? 'de_like' : 'me_like'}.png`)"
										alt="" />
									<span class="fz22 display-block marl14"
										:class="pagedata.is_favorite ? 'co-010085' : 'co-333333'">{{pagedata.favorite}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="price h120 w1200 relative">
				<div
					class="absolute left0 top0 right0 bottom0 justify-content-center-between boderra4 bg-white price-main paddlr30">
					<div class="align-items-end">
						<span class="fz56 l46 co-010085">￥{{pagedata.price}}</span>
						<span class="fz24 co-333333 display-block marl8">元</span>
					</div>
					<div class="down-btn center  co-010085 font-blod cure-pointer" @click="imBuy">
						<span class="fz16">{{btnStyle.buttonText}}</span>
						<span class="fz14">{{btnStyle.buttonText2}}</span>
					</div>
				</div>
			</div>

			<div class="bg-F7F7F7 paddt20">
				<div class="w1200 main bg-white">
					<div v-html="pagedata.content" class="html-box ql-editor content-detail">
					</div>
				</div>
			</div>
		</div>
		<!-- 素材市场结束-->
		<div class="w1200 main paddt20 bg-white" v-if="type==5||type==2||type==3||type==4">
			<div class="paddlr20">
				<div class="fz28 co-333333 font-blod">{{pagedata.title}}</div>
				<div class="justify-content-center-between mart13">
					<div class="align-items-center fz16 co-999999">
						<img class="de-01 display-block" src="@/assets/img/de_01.png" alt="" />
						<span class="display-block marl12">{{pagedata.create_time}}发布</span>
						<img class="de-03 display-block marl36" src="@/assets/img/de_see.png" alt="" />
						<span class="display-block marl12">{{pagedata.view}}</span>
						<img class="de-03 display-block marl36" src="@/assets/img/de_03.png" alt="" />
						<span class="display-block marl12">{{pagedata.favorite}}</span>
					</div>
					<div class="align-items-center cure-pointer" @click="rightClick">
						<img class="de-04 display-block" src="@/assets/img/de_04.png" alt="" />
						<img class="de-04 display-block marl32" src="@/assets/img/de_02.png" alt="" />
						<img class="de-04 display-block marl32" src="@/assets/img/de_05.png" alt="" />
						<img class="de-04 display-block marl32" src="@/assets/img/de_06.png" alt="" />
					</div>
				</div>
				<div v-html="pagedata.content" class="html-box ql-editor content-detail">
				</div>
				<loadMore :state="state" loading_icon></loadMore>
				<div class="center mart40 paddb30">
					<div class="center like-btn cure-pointer marr40"
						:class="pagedata.is_collect==1? 'bg-DCFF03 like-btn-active' : ''" @click="like(2)">
						<img class="de-07 display-block"
							:src="require(`@/assets/img/${pagedata.is_collect==1? 'de_collect' : 'de_no_collect'}.png`)"
							alt="" />
						<span class="fz22 display-block marl14"
							:class="pagedata.is_collect==1? 'co-010085' : 'co-333333'">{{pagedata.collect}}</span>
					</div>

					<div class="center like-btn cure-pointer"
						:class="pagedata.is_favorite ? 'bg-DCFF03 like-btn-active' : ''" @click="like(1)">
						<img class="de-07 display-block"
							:src="require(`@/assets/img/${pagedata.is_favorite ? 'de_like' : 'de_07'}.png`)" alt="" />
						<span class="fz22 display-block marl14"
							:class="pagedata.is_favorite ? 'co-010085' : 'co-333333'">{{pagedata.favorite}}</span>
					</div>
				</div>
			</div>
		</div>

		<div id="map">
			<vipdownload :detail="pagedata" :obj="obj" ></vipdownload>
		</div>
		<div id="comment">	
			<comment @sendConfirm="_sendConfirm"  :info="pagedata"  :focus="isInput"  @blurInput="_blur" :obj="obj" :cid="cid" :pla="pla" ></comment>
		</div>
		<likeComment :list="list" @replyComment="_replyComment" ></likeComment>
		<tgshare ref="sharePopup" :dataObj="dataObj"  :shareUrl="'https://pc.archipocket.com/#/Detail?id='+id+'&type='+type" ></tgshare>
	</div>
</template>				<!-- https://jianzhupc.togy.com.cn/#/Detail?type=5&id=141 -->

<script>
	import {
		useRoute
	} from 'vue-router';
	
	import vipdownload from '@/components/vipdownload/vipdownload.vue'
	import comment from '@/components/comment/comment.vue'
	export default {
		components: {
			vipdownload,
			comment
		},
		name: "Detail",
		data() {
			return {
				state: 1,
				type: 1, // 1素材市场 2研图社 3竞赛信息 4院校指南 5项目灵感
				detailUrl: '', //详情请求url
				dataObj: {},
				id: '', //跳转页面获取到的id
				likeobj: {}, //点赞的参数
				obj: {
					type: ''
				}, //评论的参数
				pagedata: {

				},
				btnStyle: {
					buttonText: '',
					buttonText2: ''
				},
				downLoadUrl: '', //素材下载的链接
				list: [], // 列表
				cid: '', //回复评论用的回复的评论id
				replyIndex: -1, //回复用的索引值
				page: 0,
				loading: false,
				isInput: false,
				pla:'',
				
			}
		},
		created() {
			let data = useRoute().query;
			this.type = data.type;
			this.id = data.id;
			if (this.type == 4) {
				// 院校指南
				this.obj.type = 'school'
				this.detailUrl = '/api/article/detail'
			} else if (this.type == 5) {
				// 项目灵感详情
				this.obj.type = 'inspiration'
				this.detailUrl = '/api/article/detail'
				this.likeobj = {
					type: 'inspiration_favorite',
					type1: 'inspiration_collect'
				}
			} else if (this.type == 1) {
				// 素材详情
				this.obj.type = 'source'
				this.detailUrl = '/api/course/detail'
				this.likeobj = {
					type: 'source_favorite',
					type1: 'source_collect'
				}
			} else if (this.type == 3) {
				// 竞赛信息
				this.obj.type = 'competition'
				this.detailUrl = '/api/article/detail'
				this.likeobj = {
					type: 'competition_favorite',
				}
			} else if (this.type == 2) {
				// 研图社详情
				this.obj.type = 'picture'
				this.detailUrl = '/api/article/detail'
				this.likeobj = {
					type: 'picture_favorite',
					type1: 'picture_collect'
				}
			}
			this.getComment();
			this.getDetail()
		},
		mounted() {
		
			let data = useRoute().query;
			if(data.share==1){
				let obj ={
					id:data.id,
					type:this.obj.type,
					uuid:localStorage.getItem('uuid'),
					share_uid:data.userId, //分享者uid
					share_time:data.shareTime
				}
				console.log(obj,'分享参数')
				this.$req({ 
				method: 'put',
				url: '/api/member/share',
				data:{
					...obj
				},
				success: res => {
					console.log(res)
				},
				fail: error => {
					console.log(error)
				}
				});
			}
		},
		methods: {
			rightClick() {					
				this.$refs.sharePopup.open()
			},
			_blur(e){
			
				this.isInput = false
				this.pla = '';
				
			},
			getComment() {
				// 获取评论列表
				this.page = ++this.page
				this.$req({
					method: 'get',
					url: '/api/comment/list',
					data: {
						id: this.id,
						type: this.obj.type,
						r_page: 1,
						r_size: 2,
						page: this.page,
						size: 10,
					},
					success: res => {
						
						this.list = this.list.concat(res)
						if (res.length < 10) {
							this.state = 2
						}
					}
				})
			},
			_sendConfirm(data) { //评论完成后查询评论
				this.list = [];
				this.page=0;
				this.getComment();	
				this.detail.comment++
			},
			_replyComment(e, i) {
				this.$nextTick(() => {
					setTimeout(() => {
						//获取指定的div盒
						let targetbox = document.getElementById('comment')
						console.log(targetbox)
						//获取这个div盒的高度位置
					let target = targetbox.offsetTop
					// 	//将滚动条定位到这个高度位置
						document.documentElement.scrollTop = target-300
					})
				})
				// 获取回复他人评论的评论id
				this.isInput = true
				this.cid = e.id
				this.replyIndex = i;
				this.pla = '回复：' + e.nickname;
				
			},
			imBuy() {
	
				if (this.pagedata.is_vip == 0) {
					if (this.btnStyle.buttonText.indexOf('下载') == -1) {

						if (this.pagedata.is_buy == 2) {
							// 存在未支付订单
							this.$message.warning('已存在未支付订单,去个人中心查看相关订单')
							return
						}
						// // 到购买页
						this.$nextTick(() => {
							setTimeout(() => {
								//获取指定的div盒
								let targetbox = document.getElementById('map')
								//获取这个div盒的高度位置
								this.target = targetbox.offsetTop
								console.log(this.target)
								//将滚动条定位到这个高度位置
								document.documentElement.scrollTop = this.target-200
							})
						})


					} else {
							// // 到购买页
							this.$nextTick(() => {
								setTimeout(() => {
									//获取指定的div盒
									let targetbox = document.getElementById('map')
									//获取这个div盒的高度位置
									this.target = targetbox.offsetTop
									console.log(this.target)
									//将滚动条定位到这个高度位置
									document.documentElement.scrollTop = this.target-200
								})
							})
							this.$utilscopy(this.downLoadUrl)
							this.$message.success('复制链接成功')
					}
				} else if (this.pagedata.is_vip == 1 || this.pagedata.is_vip == 2) {
					if (this.pagedata.is_vip_free == 1) {
						// 滚动到附件位置
					
						this.$message('请点击附件下载按钮进行下载')
						this.$nextTick(() => {
							setTimeout(() => {
								//获取指定的div盒
								let targetbox = document.getElementById('map')
								//获取这个div盒的高度位置
								this.target = targetbox.offsetTop
								console.log(this.target)
								//将滚动条定位到这个高度位置
								document.documentElement.scrollTop = this.target-200
							})
						})
					} else {
						if (this.btnStyle.buttonText.indexOf('下载') == -1) {
							if (this.pagedata.is_buy == 2) {
								// 存在未支付订单
								this.$message.warning('已存在未支付订单')
								return
							}
							// 到购买页
							console.log('去购买页')
						
						} else {
							this.$utilscopy(this.downLoadUrl)
							this.$message.success('复制链接成功')
						}
					}
				}
			},
			dowloadUrl(url, fileName) {
				// 打开浏览器并下载
				this.dowloanUrl = url;
				this.$message.success("下载成功");
				fetch(url).then(res => res.blob()).then(blob => {
					let eleLink = document.createElement("a");
					eleLink.style.display = "none";
					eleLink.target = "_blank";
					eleLink.setAttribute('download', fileName);
					eleLink.href = URL.createObjectURL(blob);
					document.body.appendChild(eleLink);
					eleLink.click();
					URL.revokeObjectURL(eleLink.href);
					document.body.removeChild(eleLink);
				})
			},
			getDetail() {
				this.state = 1;
				this.$req({
					method: 'get',
					url: this.detailUrl,

					data: {
						id: this.id,
					},
					success: res => {
						this.state = 0;
						this.pagedata = res;
						this.dataObj = { //分享参数
							title: res.title,
							id: this.id,
							type: this.obj.type,
						}
							
						
						if (!res.can_download) {
							this.btnStyle.buttonText = '购买：￥' + res.price
							if (this.type == 1) {
								// 素材
								this.btnStyle.buttonText2 = res.vip_free_desc
							}
							if(res.is_vip_free == 1){
								this.btnStyle.buttonText = '购买：￥' + res.price
								this.btnStyle.buttonText2 =''
							}
						} else {
							if (res.is_vip == 1 && res.is_vip_free == 1) {
								
								this.downLoadUrl = res.bdy_link
								this.btnStyle.buttonText = '会员免费下载'
								// 获取文件所在位置
								// this.$nextTick(() => {
								// 	setTimeout(() => {
								// 		//获取指定的div盒
								// 		let targetbox = document.getElementById('map')
								// 		//获取这个div盒的高度位置
								// 		this.target = targetbox.offsetTop
								// 		console.log(this.target)
								// 		//将滚动条定位到这个高度位置
								// 		document.documentElement.scrollTop = this.target-200
								// 	})
								// })
							} else {

								// 获取下载链接
								console.log('获取下载链接')
								this.$req({
									method: 'get',
									data: {
										id: this.id,
									},
									url: '/api/course/sourceFile',
									success: res1 => {
										this.downLoadUrl = res1
										console.log(res1, "下载链接")
										this.btnStyle.buttonText = '下载'
									},
									fail: error => {
										this.btnStyle.buttonText = '购买：￥' + res.price
										if (this.type == 1) {
											// 素材
											this.btnStyle.buttonText2 = res.vip_free_desc
										}
									}
								});

							}
						}
					},
					fail: error => {}
				});
			},
			// 收藏/点赞
			like(e) {
				
				let msg = '',
					key = '',
					type = ''
				if (e == 1) {
					key = 'is_favorite'
					if (this.type == 2) {
						// 研图社
						type = 'picture_favorite'
					} else if (this.type == 5) {
						// 项目灵感
						type = 'inspiration_favorite'
					} else if (this.type == 3) {
						// 素材详情
						type = 'competition_favorite'
					} else if (this.type == 1) {
						type = 'source_favorite'
					} else {
						type = 'school_favorite'
					}
					msg = '点赞'
				} else {
					key = 'is_collect'
					if (this.type == 2) {
						// 研图社
						type = 'picture_collect'
					} else if (this.type == 5) {
						// 项目灵感
						type = 'inspiration_collect'
					} else if (this.type == 1) {
						type = 'source_collect'
					} else {
						type = 'school_collect'
					}
					msg = '收藏'

				}
				console.log({
					id: this.id,
					type,
				}, msg)

				this.$req({
					method: 'post',
					url: '/api/mapleCollection',
					data: {
						id: this.id,
						type: type,
					},
					success: res => {
						if (e == 2) {
							if (this.pagedata.is_collect == 1) {
								this.pagedata.is_collect = 0
								this.pagedata.collect--;
								this.$message.warning("取消收藏");

							} else {
								this.pagedata.is_collect = 1
								this.pagedata.collect++;
								this.$message.success("收藏成功");
							}
						}
						if (e == 1) {
							if (this.pagedata.is_favorite == 1) {
								this.pagedata.is_favorite = 0
								this.pagedata.favorite--;
								this.$message.warning("取消点赞");
							} else {
								this.pagedata.is_favorite = 1
								this.pagedata.favorite++;
								this.$message.success("点赞成功");
							}
						}
					},
					fail: error => {
						console.log(error)
					}
				});

			}
		}
	}
</script>

<style scoped>
	.em-box {
		width: 320px;
		height: 150px;
		padding: 13px;
		overflow-y: hidden;
		background: #FFFFFF;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		right: -96px;
		top: 38px;


	}

	.em-box-inside {
		border-bottom: 1px solid #E6E6E6;

		.em-item {
			width: 30px;
			height: 30px;
			border-left: 1px solid #E6E6E6;
			border-top: 1px solid #E6E6E6;
			border-right: 1px solid #E6E6E6;
		}
	}

	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
		margin-bottom:8px;
	}

	.main {
		margin: 0 auto;


	}

	.de-01 {
		width: 20px;
		height: 20px;
	}

	.de-03 {
		width: 20px;
		height: 20px;
	}

	.de-04 {
		width: 32px;
		height: 32px;
	}

	.mian-pic {
		width: 1160px;
		height: 450px;
	}

	.like-btn {
		padding: 0 28px;
		height: 50px;
		border: 1px solid #999999;
		border-radius: 30px;
	}

	.like-btn-active {
		border: 1px solid #DCFF03 !important;
	}

	.de-07 {
		width: 30px;
		height: 30px;
	}

	.main-content {
		padding-left: 20px;
		padding-right: 19px;
	}

	.textarea {
		padding: 10px;
		height: 54px;
		outline: none;
		background: #F7F7F7;
		border: 0;
		resize: none;
	}

	.head-pic-01 {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	.de_08 {
		width: 20px;
		height: 20px;
	}

	.head-pic {
		width: 50px;
		height: 58px;
	}

	.submit-btn {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}

	.head-pic-02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.comment-like {
		width: 20px;
		height: 20px;
	}

	

	.bg-pic {
		height: 450px;
		filter: blur(16px);
		-webkit-filter: blur(15px);
	}

	.top-main {
		margin: 0 auto;


	}

	.img_02 {
		width: 285px;
		height: 220px;
		border-radius: 8px;
	}

	.de_01 {
		width: 32px;
		height: 32px;
	}

	.btn-01 {
		padding: 0 20px;
		height: 50px;
		background: #FFFFFF;
		opacity: 1;
		border-radius: 30px;
	}

	.price {
		margin: 0 auto;
		margin-top: -60px;

	}

	.price-main {
		z-index: 10;
	}

	.down-btn {
		width: 158px;
		height: 60px;
		background: #DCFF03;
		border-radius: 4px;
		flex-direction: column;
	}

	.main {
		margin: 0 auto;


	}

	.main-pic {
		height: 450px;
	}

	.main-content {
		padding-left: 20px;
		padding-right: 19px;
	}

	.textarea {
		padding: 10px;
		width: 1140px;
		height: 54px;
		outline: none;
		background: #F7F7F7;
		border: 0;
		resize: none;
	}

	.head-pic-01 {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	.de_08 {
		width: 20px;
		height: 20px;
	}

	.submit-btn {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}

	.head-pic-02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.comment-like {
		width: 20px;
		height: 20px;
	}
</style>
